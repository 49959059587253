<template>
  <div>
    <template v-if="voicemailsSignedUrlFinished">
      <c-t-audio-controls :audio-source="selectedVoicemailSignedMediaUrl" />
    </template>
    <ct-centered-spinner v-else />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { phoneNumber, datetimeLong } from '@/directives/directives'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import CTAudioControls from '@/components/CTAudioControls'

export default {
  name: 'VirtualPhoneVoicemailsAudioPlayback',
  components: { CTAudioControls, CtCenteredSpinner },
  directives: {
    datetimeLong,
    phoneNumber,
  },

  computed: {
    ...mapGetters('virtualPhones', ['voicemailsSignedUrlFinished', 'selectedVoicemailSignedMediaUrl']),
  },

  methods: {
    ...mapActions('virtualPhones', ['setSelectedPhoneId']),
  },
}
</script>

<style lang="scss" scoped>


</style>
