var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "voicemail-list" } }, [
    _c(
      "ul",
      {
        staticClass: "list-group",
        style: { height: `${_vm.idealListHeight}px`, overflow: "scroll" },
      },
      _vm._l(_vm.virtualPhoneVoicemails, function (voicemail) {
        return _c(
          "li",
          {
            key: voicemail.id,
            class: _vm.getItemClass(voicemail),
            on: {
              click: function ($event) {
                return _vm.select(voicemail)
              },
            },
          },
          [
            _c("virtual-phone-voicemails-list-item", {
              attrs: { voicemail: voicemail },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }