<template>
  <div id="voicemail-list">
    <ul class="list-group" :style="{height: `${idealListHeight}px`, overflow:'scroll'}">
      <li
        v-for="voicemail in virtualPhoneVoicemails"
        :key="voicemail.id"
        :class="getItemClass(voicemail)"
        @click="select(voicemail)"
      >
        <virtual-phone-voicemails-list-item :voicemail="voicemail" />
      </li>
    </ul>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'
import VirtualPhoneVoicemailsListItem from '@/components/VirtualPhones/VirtualPhoneVoicemailsListItem'

export default {
  name: 'VirtualPhoneVoicemailsList',
  components: { VirtualPhoneVoicemailsListItem },
  directives: {
    phoneNumber,
  },

  computed: {
    ...mapGetters('virtualPhones', ['virtualPhoneVoicemails', 'selectedVoicemail']),

    // Maybe theres a better way... I couldn't get it to resize perfectly with just CSS/Bootstrap.
    idealListHeight () {
      const offsets =
        60 // top nav
        + 89 // masthead
        + 40 // horizontal rule

      return window.innerHeight - offsets
    },
  },

  methods: {
    ...mapActions('virtualPhones', ['setSelectedVoicemailId']),

    select(voicemail) {
      this.setSelectedVoicemailId(voicemail.id)
      this.$emit('changed')
    },

    getItemClass(voicemail) {
      const selectedOrNothing = this.itemIsActive(voicemail) ? 'selected' : ''
      return `list-group-item ${selectedOrNothing}`
    },

    itemIsActive(voicemail) {
      return voicemail.id === this.selectedVoicemail.id
    },
  },
}
</script>

<style lang="scss" scoped>

li.selected {
  background-color: #EDECF6;
}

</style>
