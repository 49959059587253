<template>
  <div>
    <b-card class="voicemails-card">
      <ct-centered-spinner v-if="!voicemailsFinished" height="200px" />

      <div v-if="voicemailsFinished && selectedVoicemail">
        <b-button
          class="delete-voicemail"
          variant="outline-primary"
          @click="downloadVoicemail"
        >
          Download
        </b-button>

        <b-button
          class="delete-voicemail"
          variant="outline-danger"
          @click="deleteVoicemail"
        >
          Delete
        </b-button>

        <span class="faint-header">Voicemail from:</span>
        <span class="caller">{{ contactNameOrPhoneNumber(people, selectedVoicemail.from_number.substring(2), 'Unknown Caller', isNotAccountOwner) }}
          <span v-if="!this.isNotAccountOwner" v-phone-number="selectedVoicemail.from_number" class="inbound-number" />
        </span>

        <div class="additional-details">
          For {{ selectedPhone.name }}
          <span v-phone-number="selectedVoicemail.to_number" class="ml-1" />
          <span v-datetime-long="selectedVoicemail.call_ended_at" class="extended-datetime" />
        </div>

        <hr class="w-100 mt-4 mb-4">

        <div v-if="!isNotAccountOwner">
          <span class="faint-header">Voicemail Transcript:</span>
          <p class="transcript">
            {{ selectedVoicemail.transcription }}
          </p>

          <virtual-phone-voicemails-audio-playback class="mt-2 pt-2" />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { phoneNumber, datetimeLong } from '@/directives/directives'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import VirtualPhoneVoicemailsAudioPlayback from '@/components/VirtualPhones/VirtualPhoneVoicemailsAudioPlayback'
import { contactNameOrPhoneNumber } from '@/helpers'

export default {
  name: 'VirtualPhoneVoicemailsCard',
  components: { VirtualPhoneVoicemailsAudioPlayback, CtCenteredSpinner },
  directives: {
    datetimeLong,
    phoneNumber,
  },

  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone', 'selectedVoicemail', 'voicemailsFinished', 'selectedVoicemailSignedMediaUrl']),
    ...mapGetters('account', ['people', 'isNotAccountOwner']),
  },

  methods: {
    ...mapActions('virtualPhones', ['deleteSelectedVoicemail', 'markSelectedVoicemailDownloaded']),

    contactNameOrPhoneNumber: contactNameOrPhoneNumber,
    deleteVoicemail() {
      this.deleteSelectedVoicemail(this)
    },
    failedToDownloadVoicemailToast(){
      this.$bvToast.toast('There was a problem downloading the voicemail. Please try again.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
    },
    async downloadVoicemail(){
      try {
        const url = this.selectedVoicemailSignedMediaUrl
        if (url) {
          window.open(url, '_blank')
          await this.markSelectedVoicemailDownloaded()
        }
      } catch (error) {
        this.failedToDownloadVoicemailToast()
      }
    },
  },
}
</script>


<style lang="scss" scoped>

.voicemails-card {

  .delete-voicemail {
    float: right;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: -6px;
    border: none;
    font-size: 14px;
  }

  hr {
    border: none;
    height: 2px;
    color: #CCCCCC;
    background-color: #CCCCCC;
  }

  .faint-header {
    font-size: 15px;
    color: #828282;
    font-weight: 600;
  }

  .transcript {
    padding-top: 12px;
    display: block;

  }

  .caller {
    padding-top: 5px;
    display: block;
    color: #363636;
    line-height: 20px;
    font-size: 20px;
    font-weight: 600;
    margin-right: 12px;
  }

  .inbound-number {
    color: #4A4A4A;
    line-height: 20px;
    font-size: 20px;
    font-weight: 600;
  }

  .additional-details {
    padding-top: 25px;
    display: block;
    color: #6A6A6A;
    font-weight: 600;
    line-height: 16px;
    font-size: 14px;
  }

  .extended-datetime {
    padding-top: 10px;
    display: block;
  }
}

</style>
