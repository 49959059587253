<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center ml-0 mb-1">
      <div class="mb-0">
        <span class="space-for-message-indicator">
          <span v-if="voicemail.status === 'new'" class="unheard-message-indicator" />
        </span>
        <span class="caller">{{ contactNameOrPhoneNumber(people, voicemail.from_number.substring(2), 'Unknown Caller', isNotAccountOwner) }}</span>
      </div>
      <span v-friendly-date="voicemail.call_ended_at" class="friendly-date" />
    </b-row>

    <b-row class="ml-0 mb-0">
      <span v-if="!this.isNotAccountOwner" v-phone-number="voicemail.from_number" class="inbound-number" />
    </b-row>
  </div>
</template>

<script>

import { phoneNumber, friendlyDate } from '@/directives/directives'
import { mapGetters } from "vuex"
import { contactNameOrPhoneNumber } from '@/helpers'

export default {
  name: 'VirtualPhoneVoicemailsListItem',

  directives: {
    phoneNumber,
    friendlyDate,
  },

  props: {
    voicemail: {
      type: Object,
      value: {},
    },
  },

  computed: {
    ...mapGetters('account', ['people', 'isNotAccountOwner']),
  },
  methods: {
    contactNameOrPhoneNumber: contactNameOrPhoneNumber,
  },
}
</script>

<style lang="scss" scoped>

.space-for-message-indicator {
  box-sizing: border-box;
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 5px;
}

.unheard-message-indicator {
  box-sizing: border-box;
  display: inline-block;
  background-color: #F46E36;
  border-radius: 160px;
  height: 14px;
  width: 14px;
}

.caller {
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
}

.inbound-number {
  font-size: 14px;
  padding-left: 19px;
}

.friendly-date {
  font-size: 12px;
  margin-right: 4px;
}


</style>
