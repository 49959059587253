<template>
  <b-container class="phones-page">
    <virtual-phone-masthead
      title="Voicemail for"
      @changed="fetchData"
    />


    <b-row v-if="loaded" class="d-flex align-content-start flex-wrap">
      <template v-if="selectedPhoneHasVoicemails">
        <!-- If med-xl screen, place this on the right (order 1). Otherwise place above (order 0).-->
        <b-col class="order-md-1" md="8" sm="12">
          <virtual-phone-voicemails-card />
        </b-col>

        <b-col md="4" sm="12" class="m-0 p-0">
          <virtual-phone-voicemails-list />
        </b-col>
      </template>

      <p v-else>
        This number has not received any voicemails yet.
      </p>
    </b-row>
    <b-row v-if="!arePhonesActive">
      <p>There are currently no active phones.</p>
    </b-row>
  </b-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import VirtualPhoneMasthead from '@/components/VirtualPhones/VirtualPhoneMasthead'
import VirtualPhoneVoicemailsList from '@/components/VirtualPhones/VirtualPhoneVoicemailsList'
import VirtualPhoneVoicemailsCard from '@/components/VirtualPhones/VirtualPhoneVoicemailsCard'

export default {
  name: 'Voicemails',
  components: { VirtualPhoneVoicemailsCard, VirtualPhoneVoicemailsList, VirtualPhoneMasthead },

  props: {
    virtualPhoneId: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      query: null,
      voicemailList: null,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', ['voicemailsFinished', 'virtualPhonesFinished', 'virtualPhoneVoicemails','selectedPhone']),

    loaded () {
      return this.virtualPhonesFinished && this.voicemailsFinished
    },

    selectedPhoneHasVoicemails () {
      return this.virtualPhoneVoicemails && this.virtualPhoneVoicemails.length > 0
    },

    arePhonesActive (){
      return this.selectedPhone
    },
  },

  async mounted () {
    await this.fetchData()
  },

  methods: {
    ...mapActions('virtualPhones', ['loadVirtualPhones', 'loadVoicemailsForSelectedVirtualPhone']),

    async fetchData () {
      await this.loadVirtualPhones()
      await this.loadVoicemailsForSelectedVirtualPhone()
    },
  },
}
</script>
