var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticClass:
            "d-flex justify-content-between align-items-center ml-0 mb-1",
        },
        [
          _c("div", { staticClass: "mb-0" }, [
            _c("span", { staticClass: "space-for-message-indicator" }, [
              _vm.voicemail.status === "new"
                ? _c("span", { staticClass: "unheard-message-indicator" })
                : _vm._e(),
            ]),
            _c("span", { staticClass: "caller" }, [
              _vm._v(
                _vm._s(
                  _vm.contactNameOrPhoneNumber(
                    _vm.people,
                    _vm.voicemail.from_number.substring(2),
                    "Unknown Caller",
                    _vm.isNotAccountOwner
                  )
                )
              ),
            ]),
          ]),
          _c("span", {
            directives: [
              {
                name: "friendly-date",
                rawName: "v-friendly-date",
                value: _vm.voicemail.call_ended_at,
                expression: "voicemail.call_ended_at",
              },
            ],
            staticClass: "friendly-date",
          }),
        ]
      ),
      _c("b-row", { staticClass: "ml-0 mb-0" }, [
        !this.isNotAccountOwner
          ? _c("span", {
              directives: [
                {
                  name: "phone-number",
                  rawName: "v-phone-number",
                  value: _vm.voicemail.from_number,
                  expression: "voicemail.from_number",
                },
              ],
              staticClass: "inbound-number",
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }